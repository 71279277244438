var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "facility-config-component table-component" },
    [
      _c(
        "div",
        { staticClass: "page-body page-body-margin-left-16" },
        [
          _c(
            "a-card",
            { staticClass: "card-item", attrs: { title: "DHC能效配置" } },
            [
              _c(
                "div",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra" },
                  slot: "extra"
                },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: {
                        type: "primary",
                        "click-prop": _vm.saveConfigClick
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              ),
              _c(
                "a-spin",
                {
                  staticClass: "dhc-loading",
                  attrs: { spinning: _vm.dhcLoading }
                },
                [_c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }